import { AddressPayload, AddressType, emptyAddressPayload, HofyWarehouseType } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';

export interface HofyWarehousePayload {
    name: string;
    address: AddressPayload;

    type: HofyWarehouseType | null;
    hofySubsidiaryId: UUID | null;
    countries: Country[];

    dataErasureSupported: boolean;
}

export const emptyHofyWarehousePayload: HofyWarehousePayload = {
    name: '',
    type: null,
    address: emptyAddressPayload(AddressType.Billing),
    hofySubsidiaryId: null,
    countries: [],
    dataErasureSupported: false,
};
