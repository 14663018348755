import React, { FC } from 'react';

import { BYODOrderDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { Box, FormGridRow, Labeled, LabeledText } from '@hofy/ui';

import { ShipmentTrackingLink } from '../../../shipmentsPage/components/table/ShipmentTrackingLink';

interface BYODOrderDetailsProps {
    byodOrder: BYODOrderDto;
}

export const BYODOrderDetails: FC<BYODOrderDetailsProps> = ({ byodOrder }) => {
    return (
        <Box>
            <FormGridRow columns={4} marginVertical={20}>
                <LabeledText label='Organization' content={byodOrder.organization.name} />
                <LabeledText label='Warehouse' content={byodOrder.toWarehouse.name} />
                <Labeled
                    label='Tracking link'
                    content={<ShipmentTrackingLink link={byodOrder.trackingLink} trackingId='link' />}
                />
                <LabeledText label='Exp. delivery date' content={formatDate(byodOrder.estimatedReceiveOn)} />
            </FormGridRow>
        </Box>
    );
};
