import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { useHofySubsidiaryRefs } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { UUIDRoute } from '@hofy/router';
import { Box, Button, SvgIcon } from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { CreateUpdateWarehouseSlideout } from './CreateUpdateWarehouseSlideout';
import { WarehouseTable } from './WarehouseTable';

interface WarehousesRouterProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const WarehouseRouter: FC<WarehousesRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const { goBack } = useGoBack();
    const { hasPermission } = useSession();
    const { hofySubsidiaries } = useHofySubsidiaryRefs();

    const onUpdateClick = (id: UUID) => {
        history.push(`${AdminNavLink.Settings}/${AdminSettingsTab.Warehouses}/${id}/update`);
    };

    const handleAddWarehouse = () => {
        history.push(`${AdminNavLink.Settings}/${AdminSettingsTab.Warehouses}/add`);
    };

    return (
        <Box column flex='auto'>
            <SettingsHeader
                tabs={tabs}
                tab={tab}
                onChangeTab={onChangeTab}
                titleContent={
                    <Button
                        leftIcon={SvgIcon.Add}
                        label='Add Warehouse'
                        disabled={!hasPermission(Permission.AdminWarehouseManage)}
                        onClick={handleAddWarehouse}
                    />
                }
            />
            <WarehouseTable onUpdateClick={onUpdateClick} />
            <Route path={`${AdminNavLink.Settings}/${AdminSettingsTab.Warehouses}/add`}>
                <CreateUpdateWarehouseSlideout hofySubsidiaries={hofySubsidiaries} onClose={goBack} />
            </Route>
            <UpdateWarehouseRoute path={`${AdminNavLink.Settings}/${AdminSettingsTab.Warehouses}/:id/update`}>
                {({ id }) => (
                    <CreateUpdateWarehouseSlideout
                        warehouseId={id}
                        hofySubsidiaries={hofySubsidiaries}
                        onClose={goBack}
                    />
                )}
            </UpdateWarehouseRoute>
        </Box>
    );
};

const UpdateWarehouseRoute = UUIDRoute('id', Route);
