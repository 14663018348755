import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';

import { getAssignmentLink } from '../../components/routing/adminLinks';
import { Link } from '../../components/routing/Link';

interface AssignmentDetailsLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const AssignmentDetailsLink: FC<AssignmentDetailsLinkProps> = ({ id, children }) => {
    return <Link to={getAssignmentLink(id)}>{children}</Link>;
};
