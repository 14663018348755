import { difference, sortBy } from 'lodash';
import React, { FC } from 'react';

import { useShipmentQuery } from '@hofy/api-admin';
import { isShipmentFromSupplierToUser } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { allCurrencies, coreCurrencies } from '@hofy/global';
import { useCopyToClipboard } from '@hofy/hooks';
import { useTrCurrency } from '@hofy/i18n';
import {
    FormDateInput,
    FormInput,
    FormSegment,
    FormTextarea,
    IconButton,
    SvgIcon,
    TextCell,
    UseForm,
} from '@hofy/ui';

import { FormHofyWarehouseSelect } from '../../../../../components/domain/warehouses/FormHofyWarehouseSelect';
import { PurchaseOrderHeaderFormData } from '../../../../../store/purchaseOrders/usePurchaseOrderHeaderForm';
import { PurchaseOrderFormSupplierDropdown } from './PurchaseOrderFormSupplierDropdown';

interface PurchaseOrderHeaderFormProps {
    headerForm: UseForm<PurchaseOrderHeaderFormData>;
}

export const PurchaseOrderHeaderForm: FC<PurchaseOrderHeaderFormProps> = ({ headerForm }) => {
    const { copyToClipboard } = useCopyToClipboard(headerForm.values.purchaseOrderReference ?? '');
    const trCurrency = useTrCurrency();
    const { data: shipment } = useShipmentQuery(headerForm.values.shipmentId);

    return (
        <FormSegment column gap={20}>
            {shipment && isShipmentFromSupplierToUser(shipment) && (
                <TextCell bold>
                    Shipment going to user: {`${shipment.toUser.firstName} ${shipment.toUser.lastName}`}
                </TextCell>
            )}

            <FormInput
                api={headerForm.fields.purchaseOrderReference}
                label='Purchase order reference'
                disabled
                nullable
            >
                <IconButton icon={SvgIcon.Copy} onClick={copyToClipboard} marginLeft={10} />
            </FormInput>
            <FormInput
                api={headerForm.fields.purchaseOrderSupplierReference}
                label='Supplier reference'
                nullable
            />
            <PurchaseOrderFormSupplierDropdown headerForm={headerForm} />
            <FormDropdown
                label='Currency'
                items={sortBy(difference(allCurrencies, coreCurrencies), c => trCurrency(c))}
                fixedItems={sortBy(coreCurrencies, c => trCurrency(c))}
                searchable
                toSearchLabel={c => [trCurrency(c).toLowerCase(), trCurrency(c).toUpperCase()]}
                value={headerForm.values.currency}
                onChange={currency =>
                    headerForm.setValues({
                        currency,
                    })
                }
                labelFormatter={trCurrency}
                isError={headerForm.errors.currency}
                isRequired
            />

            {!headerForm.values.isDropship && (
                <FormHofyWarehouseSelect
                    label='Warehouse'
                    api={headerForm.fields.toWarehouseId}
                    isRequired
                    nullable
                />
            )}
            <FormDateInput label='Order date' api={headerForm.fields.orderedOn} isRequired />
            <FormDateInput
                label='Delivery date'
                api={headerForm.fields.estimatedReceiveOn}
                isRequired
                nullable
            />
            <FormTextarea label='Notes' api={headerForm.fields.notes} nullable rows={5} />
        </FormSegment>
    );
};
