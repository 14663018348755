import React, { FC } from 'react';

import { AssignmentDetailsDto } from '@hofy/api-admin';
import {
    AssignmentStatus,
    AssignmentType,
    formatUserName,
    getAssignmentStoreAndReuse,
    isDeliveryPending,
    Ownership,
    RentalStoreAndReuseLocation,
} from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { BooleanBadge, Box, FormGridRow, Labeled, LabeledText } from '@hofy/ui';

import { AssignmentCompletionReasonBadge } from '../../../components/domain/assignments/badge/AssignmentCompletionReasonBadge';
import { AssignmentTypeBadge } from '../../../components/domain/assignments/badge/AssignmentTypeBadge';
import { ConsumableBadge } from '../../../components/domain/assignments/badge/ConsumableBadge';
import { InPersonTransferBadge } from '../../../components/domain/assignments/badge/InPersonTransferBadge';
import { OnHoldBadge } from '../../../components/domain/assignments/badge/OnHoldBadge';
import { RefurbishedBadge } from '../../../components/domain/assignments/badge/RefurbishedBadge';
import { ScheduledCollectionBadge } from '../../../components/domain/assignments/badge/ScheduledCollectionBadge';
import { StoreAndReuseBadge } from '../../../components/domain/assignments/badge/StoreAndReuseBadge';
import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { Link } from '../../../components/routing/Link';
import { AssignmentDetailsLink } from '../../assignmentsPage/AssignmentDetailsLink';
import { AssignmentCollectionReasonCell } from '../../assignmentsPage/components/assignments/cells/AssignmentCollectionReasonCell';
import { UserDetailsLink } from '../../usersPage/UserDetailsLink';

interface AssignmentDetailsProps {
    assignment: AssignmentDetailsDto;
}

export const AssignmentDetails: FC<AssignmentDetailsProps> = ({ assignment }) => {
    const specialOrderType =
        assignment.type !== AssignmentType.Standard ||
        assignment.isStoreAndReuseCollection ||
        assignment.isOnHold ||
        assignment.isStoreAndReuseDelivery ||
        assignment.product.isRefurbished ||
        assignment.isConsumable;

    const storeAndReuse = getAssignmentStoreAndReuse(assignment.contract);

    return (
        <FormGridRow columns={4}>
            <LabeledText label='Id' content={assignment.id} />
            <LabeledText label='Public Id' content={assignment.publicId} />
            <LabeledText
                label='Order placed'
                content={formatDate(assignment.statusDates[AssignmentStatus.DeliveryPending] as DateString)}
                flex={1}
            />
            <LabeledText
                label='Collection placed'
                content={formatDate(assignment.statusDates[AssignmentStatus.CollectionPending] as DateString)}
                flex={1}
            />
            <LabeledText
                flex={1}
                label='User Id'
                content={<UserDetailsLink id={assignment.userId}>{assignment.userId}</UserDetailsLink>}
            />
            <LabeledText
                flex={1}
                label='Request Id'
                content={
                    <Link to={`${AdminNavLink.Orders}/${assignment.requestId}`}>{assignment.requestId}</Link>
                }
            />
            {assignment.deliveryCreatedBy && (
                <LabeledText
                    flex={1}
                    label='Order created by'
                    content={
                        <UserDetailsLink id={assignment.deliveryCreatedBy.id}>
                            {formatUserName(assignment.deliveryCreatedBy)}
                        </UserDetailsLink>
                    }
                />
            )}
            {assignment.collectionCreatedBy && (
                <LabeledText
                    flex={1}
                    label='Collection created by'
                    content={
                        <UserDetailsLink id={assignment.collectionCreatedBy.id}>
                            {formatUserName(assignment.collectionCreatedBy)}
                        </UserDetailsLink>
                    }
                />
            )}
            {assignment.parentAssignment !== null && (
                <LabeledText
                    flex={1}
                    label='Linked assignment'
                    content={
                        <AssignmentDetailsLink id={assignment.parentAssignment.id}>
                            {assignment.publicId}
                        </AssignmentDetailsLink>
                    }
                />
            )}
            {assignment.collectionReason && (
                <Labeled
                    label='Collection reason'
                    content={<AssignmentCollectionReasonCell reason={assignment.collectionReason} />}
                />
            )}
            {assignment.exception && (
                <Labeled
                    label='Completion reason'
                    content={<AssignmentCompletionReasonBadge reason={assignment.exception} />}
                />
            )}
            {specialOrderType && (
                <Labeled
                    label='Assignment details'
                    content={
                        <Box column gap={5} alignItems='flex-start'>
                            <AssignmentTypeBadge type={assignment.type} />
                            {assignment.product.isRefurbished && <RefurbishedBadge />}
                            {assignment.isOnHold && <OnHoldBadge />}
                            {assignment.isConsumable && <ConsumableBadge />}
                            {assignment.contract && assignment.isStoreAndReuseCollection && (
                                <StoreAndReuseBadge
                                    type='collection'
                                    isLocalRegion={
                                        storeAndReuse?.collectToLocation === RentalStoreAndReuseLocation.Local
                                    }
                                    country={storeAndReuse?.country ?? null}
                                />
                            )}
                            {assignment.contract && assignment.isStoreAndReuseDelivery && (
                                <StoreAndReuseBadge
                                    type='delivery'
                                    isLocalRegion={
                                        storeAndReuse?.deliverFromLocation ===
                                        RentalStoreAndReuseLocation.Local
                                    }
                                    country={storeAndReuse?.country ?? null}
                                />
                            )}
                            <InPersonTransferBadge
                                assignmentStatus={assignment.status}
                                exception={assignment.exception}
                                hasCollection={!!assignment.collectionShipmentId}
                                hasDelivery={!!assignment.deliveryShipmentId}
                            />
                            <ScheduledCollectionBadge
                                assignmentStatus={assignment.status}
                                notBefore={assignment.collectionNotBefore}
                            />
                        </Box>
                    }
                />
            )}
            {assignment.item?.ownership.type === Ownership.Organization && (
                <>
                    <Labeled
                        label='Delivery without insurance consented'
                        content={<BooleanBadge value={assignment.deliveryWithoutInsuranceConsented} />}
                    />
                    {!isDeliveryPending(assignment.status) && (
                        <Labeled
                            label='Collection without insurance consented'
                            content={<BooleanBadge value={assignment.collectionWithoutInsuranceConsented} />}
                        />
                    )}
                </>
            )}
        </FormGridRow>
    );
};
