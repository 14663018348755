import React, { FC } from 'react';

import { MessageDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import {
    ErrorStatePlaceholder,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';

import { MessageStatusChip } from '../../../views/usersPage/messagesTab/MessageStatusChip';

interface MessagesTableProps {
    messages: MessageDto[];
    onOpenMessage(id: UUID): void;
    infinityScroll: InfiniteScrollConfig;
    isError?: boolean;
}

export const MessagesTable: FC<MessagesTableProps> = ({
    messages,
    onOpenMessage,
    infinityScroll,
    isError,
}) => {
    return (
        <InfinityScrollTable
            data={messages}
            toKey={message => message.id}
            infinityScroll={infinityScroll}
            onRowClick={message => onOpenMessage(message.id)}
            emptyContent={
                isError ? (
                    <ErrorStatePlaceholder />
                ) : (
                    <Placeholder
                        illustration={SvgIllustration.List}
                        title='No text messages'
                        message='No text messages for selected criteria'
                    />
                )
            }
            flex='auto'
            columns={[
                {
                    id: 'to',
                    header: 'To',
                    flexGrow: 2,
                    renderer: message => message.messageTo,
                },
                {
                    id: 'created-at',
                    header: 'Created at',
                    renderer: message => formatDateTime(message.createdAt),
                },
                {
                    id: 'updated-at',
                    header: 'Updated at',
                    renderer: message => formatDateTime(message.updatedAt),
                },
                {
                    id: 'text',
                    header: 'Text',
                    renderer: message => message.text,
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: message => <MessageStatusChip status={message.status} />,
                },
            ]}
        />
    );
};
