import React, { FC } from 'react';

import { AssignmentWithProductDto, ShipmentDetailsDto } from '@hofy/api-admin';
import { isDeviceCategory } from '@hofy/api-shared';
import { Box, FormCheckbox, FormInput, SeparatedBox, UseForm, useFormArrayField } from '@hofy/ui';

import {
    CompleteDeliveryShipmentFormData,
    initialCompleteDeliveryShipmentItemFormData,
} from '../../../store/shipments/useCompleteDropshipDeliveryShipment';
import { VariantDisplay } from '../../inventoryPage/purchaseOrders/purchaseOrderCreateEditSlideout/components/VariantDisplay';

interface ShipmentReceiveDropshipItemsProps {
    shipment: ShipmentDetailsDto;
    form: UseForm<CompleteDeliveryShipmentFormData>;
}

export const ShipmentReceiveDropshipItems: FC<ShipmentReceiveDropshipItemsProps> = ({ shipment, form }) => (
    <SeparatedBox column gap={10}>
        {shipment.assignments.map((assignment, index) => (
            <ShipmentReceiveDropshipItem
                form={form}
                assignment={assignment}
                index={index}
                key={assignment.id}
            />
        ))}
    </SeparatedBox>
);

interface ShipmentReceiveDropshipItemProps {
    index: number;
    assignment: AssignmentWithProductDto;
    form: UseForm<CompleteDeliveryShipmentFormData>;
}

const ShipmentReceiveDropshipItem: FC<ShipmentReceiveDropshipItemProps> = ({ index, assignment, form }) => {
    const items = useFormArrayField(
        form.fields.items,
        initialCompleteDeliveryShipmentItemFormData(assignment),
    );

    const isDevice = isDeviceCategory(assignment.product.category);
    const unknownSerialNumber = form.values.items[index].unknownSerialNumber;

    return (
        <Box column key={assignment.id} gap={10} marginBottom={8}>
            <Box row>
                <VariantDisplay variant={assignment.variant} name={assignment.product.name} imageSize={50} />
                <Box flex={1} />
                <Box minWidth={200} width={200} column>
                    <FormInput
                        label='Serial'
                        api={items.fields[index].api.serialNumber}
                        flex={1}
                        isRequired={isDevice && !unknownSerialNumber}
                        disabled={unknownSerialNumber}
                        nullable
                    />
                    {isDevice && (
                        <Box row marginTop={4}>
                            <FormCheckbox
                                api={items.fields[index].api.unknownSerialNumber}
                                label='Serial number is unknown'
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
