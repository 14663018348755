import React, { FC } from 'react';

import { InvoiceEntryDetailsDto } from '@hofy/api-admin';
import { ContractType, Permission, PublicRefDto } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { formatDate, formatTwoDates } from '@hofy/helpers';
import { useDecimal, usePrice } from '@hofy/hooks';
import { Box, FormGridItem, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { ContractTypeChip } from '../../../../components/domain/contracts/ContractTypeChip';
import { ShipmentLinkLabel } from '../../../../components/domain/shipments/ShipmentLink';
import { ContractLinkLabel } from '../../../contractsPage/ContractLink';
import { BillingEntityLink } from '../../billingEntities/BillingEntityLink';
import { TwoRefDetails } from '../../repaymentPlans/components/TwoRefDetails';
import { InvoiceEntryTypeChip } from '../components/InvoiceEntryTypeChip';
import { InvoicingStatusChip } from '../components/InvoicingStatusChip';
import { AvalaraSection } from './AvalaraSection';
import { RevenueRecognitionSection } from './RevenueRecognitionSection';

interface InvoiceEntryDetailsProps {
    entry: InvoiceEntryDetailsDto;
}

export const InvoiceEntryDetails: FC<InvoiceEntryDetailsProps> = ({ entry }) => {
    const { formatPrice } = usePrice();
    const { formatPercent } = useDecimal();
    const { hasPermission } = useSession();

    return (
        <>
            <Box flex={1} marginVertical={30} marginRight={20} column gap={30}>
                <FormSection label='Details'>
                    <FormGridRow columns={4}>
                        <Labeled label='Type' content={<InvoiceEntryTypeChip status={entry.type} />} />
                        <LabeledText
                            label='Contract'
                            content={entry.contract ? <ContractLinkLabel id={entry.contract} /> : '--'}
                        />
                        <SubContractLabel
                            managementContractId={entry.contract?.managementContract ?? null}
                            purchaseContractId={entry.contract?.purchaseContract ?? null}
                            rentalContractId={entry.contract?.rentalContract ?? null}
                        />
                        <LabeledText
                            label='Shipment'
                            content={<ShipmentLinkLabel shipment={entry.shipment} />}
                        />
                        <FormGridItem columns={2}>
                            <LabeledText label='Id / Avalara transaction code' content={entry.id} />
                        </FormGridItem>
                        <FormGridItem columns={4}>
                            <LabeledText label='Description' content={entry.description} />
                        </FormGridItem>
                        <Labeled label='Invoicing' content={<InvoicingStatusChip invoiceEntry={entry} />} />
                        <LabeledText label='Invoice date' content={formatDate(entry.invoiceTime)} />
                        {entry.invoicePeriodFrom && (
                            <FormGridItem columns={2}>
                                <LabeledText
                                    label='Invoice period'
                                    content={formatTwoDates(entry.invoicePeriodFrom, entry.invoicePeriodTo)}
                                />
                            </FormGridItem>
                        )}
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        <LabeledText label='Invoice from' content={entry.hofySubsidiary.name} />
                        <LabeledText
                            label='Invoice to'
                            content={<BillingEntityLink billingEntity={entry.billingEntity} />}
                        />
                        <LabeledText label='Tax code' content={entry.tax.code} />
                    </FormGridRow>
                    {entry.revenueRecognition.startOn !== null && (
                        <FormGridRow columns={4}>
                            <LabeledText
                                label='Revenue period'
                                content={`${formatDate(entry.revenueRecognition.startOn)} - ${formatDate(
                                    entry.revenueRecognition.endOn!,
                                )}`}
                            />
                        </FormGridRow>
                    )}
                </FormSection>
                {hasPermission(Permission.AdminRevenueRecognitionView) && (
                    <RevenueRecognitionSection
                        entries={entry.revenueRecognition.entries}
                        needsRevenueRecognition={entry.revenueRecognition.needsRevenueRecognition}
                    />
                )}
                <AuditSection model={entry} />
            </Box>
            <Box flex={1} marginVertical={30} marginLeft={20} column gap={30}>
                <FormSection label='Pricing'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Unit price' content={formatPrice(entry.unitPrice)} />
                        <LabeledText label='Discount' content={formatPercent(entry.discount)} />
                        <LabeledText label='Price' content={formatPrice(entry.price)} />
                        <LabeledText label='Quantity' content={entry.quantity} />
                        <LabeledText label='Total price' content={formatPrice(entry.total)} />
                        <LabeledText label='Tax rate' content={formatPercent(entry.tax.rate)} />
                        <LabeledText label='Tax' content={formatPrice(entry.tax.amount)} />
                        <LabeledText label='Total gross price' content={formatPrice(entry.totalGross)} />
                        {entry.tax.predictionAmount && (
                            <LabeledText
                                label='Tax prediction'
                                content={formatPrice(entry.tax.predictionAmount!)}
                            />
                        )}
                        {entry.tax.predictionRate && (
                            <LabeledText
                                label='Tax prediction rate'
                                content={formatPercent(entry.tax.predictionRate)}
                            />
                        )}
                    </FormGridRow>
                </FormSection>
                {!!entry.repaymentPlan && (
                    <FormSection label='Two'>
                        <TwoRefDetails plan={entry.repaymentPlan}>
                            {entry.instalment.reference && (
                                <LabeledText label='Installment' content={entry.instalment.reference} />
                            )}
                            {entry.instalment.commissionRate && (
                                <LabeledText
                                    label='Commission rate'
                                    content={formatPercent(entry.instalment.commissionRate)}
                                />
                            )}
                            {entry.instalment.commission && (
                                <LabeledText
                                    label='Commission'
                                    content={formatPrice(entry.instalment.commission)}
                                />
                            )}
                        </TwoRefDetails>
                    </FormSection>
                )}
                <AvalaraSection entry={entry} />
            </Box>
        </>
    );
};

interface SubContractLabelProps {
    rentalContractId: PublicRefDto | null;
    purchaseContractId: PublicRefDto | null;
    managementContractId: PublicRefDto | null;
}

const SubContractLabel: FC<SubContractLabelProps> = ({
    rentalContractId,
    purchaseContractId,
    managementContractId,
}) => {
    const LabelContent = (contractType: ContractType, subContractId: PublicRefDto) => {
        return (
            <>
                <LabeledText label='Sub contract id' content={subContractId.publicId} />
                <Labeled label='Sub contract type' content={<ContractTypeChip type={contractType} />} />
            </>
        );
    };
    if (managementContractId) {
        return LabelContent(ContractType.Management, managementContractId);
    }
    if (purchaseContractId) {
        return LabelContent(ContractType.Purchase, purchaseContractId);
    }
    if (rentalContractId) {
        return LabelContent(ContractType.Rental, rentalContractId);
    }
    return null;
};
