import React, { FC, ReactNode } from 'react';

import { ProductCategory } from '@hofy/api-shared';
import { ImageRef, ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3, SvgIcon, Tooltip, TooltipIcon } from '@hofy/ui';

interface ProductItemProps extends BoxProps {
    label: ReactNode;
    subLabel: ReactNode;
    image?: string | ImageRef | ImageRef[];
    imageSize?: number;
    usableAsLoaner?: boolean;
    needRepairs?: boolean;
    imageTooltip?: ReactNode;
    category?: ProductCategory;
}

export const ProductItem: FC<ProductItemProps> = ({
    image,
    label,
    subLabel,
    imageSize = 54,
    usableAsLoaner,
    needRepairs,
    imageTooltip,
    category,
    ...restProps
}) => {
    return (
        <Box row {...restProps}>
            {imageTooltip ? (
                <Tooltip bodySlot={imageTooltip} maxWidth='auto' interactive>
                    <ProductImage image={image} size={imageSize} category={category} />
                </Tooltip>
            ) : (
                <ProductImage image={image} size={imageSize} category={category} />
            )}
            <Box marginLeft={20}>
                <Box row>
                    {usableAsLoaner && (
                        <TooltipIcon
                            icon={SvgIcon.Home}
                            marginRight={6}
                            iconColor={Color.AccentPurple}
                            body='Loaner Item'
                        />
                    )}
                    {needRepairs && (
                        <TooltipIcon
                            icon={SvgIcon.AlertCircle}
                            marginRight={6}
                            iconColor={Color.AccentYellow}
                            body='Need repairs'
                        />
                    )}
                    {typeof label === 'string' ? (
                        <Paragraph3 color={Color.Neutral700}>{label}</Paragraph3>
                    ) : (
                        label
                    )}
                </Box>
                {typeof subLabel === 'string' ? (
                    <Paragraph3 color={Color.Neutral300}>{subLabel}</Paragraph3>
                ) : (
                    subLabel
                )}
            </Box>
        </Box>
    );
};
