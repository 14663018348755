import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import { isWriteOff, ItemStatus, Ownership, Permission, useTrItemStatus } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { Color } from '@hofy/theme';
import {
    FormContainer,
    FormModal,
    FormSelect,
    FormTextarea,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SubmitButton,
    WarnTooltipIcon,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useChangeItemStatus } from '../../../store/items/useChangeItemStatus';
import { useItemAllowedStatusesQuery } from '../../../store/items/useItemAllowedStatusesQuery';
import { UpdateLocationForm } from '../updateItemModal/UpdateLocationForm';

interface ChangeItemStatusModalProps {
    item: ItemDto;
    onCancel(): void;
}

export const ChangeItemStatusModal: FC<ChangeItemStatusModalProps> = ({ item, onCancel }) => {
    const { form, warehouses, isLoading, isError, requireLocation } = useChangeItemStatus(item, onCancel);
    const trItemStatus = useTrItemStatus();
    const { statuses } = useItemAllowedStatusesQuery(item.id);
    const { hasPermission } = useSession();

    // Only a subset of status are allowed through the API for organization items
    const filteredStatus = statuses.filter(
        status =>
            item.ownership.type === Ownership.Hofy ||
            [ItemStatus.Active, ItemStatus.Cleared, ItemStatus.WaitingForClearance].includes(status),
    );

    const renderStatus = (v: ItemStatus) => {
        if (isWriteOff(v)) {
            return (
                <Paragraph3 row textNoWrap overflow='hidden' ellipsis color={Color.ContentPrimary}>
                    {trItemStatus(v)}
                    <WarnTooltipIcon
                        marginLeft={8}
                        iconColor={Color.ContentPrimary}
                        body='Item will be written off and removed from user and warehouse'
                    />
                </Paragraph3>
            );
        }
        return trItemStatus(v);
    };
    return (
        <FormModal
            onClose={form.discard}
            onSubmit={form.submit}
            isLoading={isLoading}
            isError={isError}
            width={600}
        >
            <ModalHeader title='Change item status' />
            <ModalContent>
                <FormContainer>
                    <FormSelect
                        options={filteredStatus}
                        toLabel={renderStatus}
                        toText={trItemStatus}
                        label='New status'
                        api={form.fields.status}
                    />
                    <FormTextarea label='Explanation' api={form.fields.statusExplanation} nullable rows={5} />
                    {requireLocation && (
                        <UpdateLocationForm
                            canAddWarehouseBin={hasPermission(Permission.AdminWarehouseManage)}
                            warehouses={warehouses}
                            form={form}
                        />
                    )}
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onCancel} />
                <SubmitButton label='Change status' action='destructive' />
            </ModalFooter>
        </FormModal>
    );
};
