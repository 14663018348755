import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';

import { Link } from '../../components/routing/Link';

interface ItemDetailsLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const ItemDetailsLink: FC<ItemDetailsLinkProps> = ({ id, children }) => {
    return <Link to={`/items/item/${id}`}>{children}</Link>;
};
