import React, { FC } from 'react';

import { RepairDetailsDto } from '@hofy/api-admin';
import { formatAdminName, formatVariant } from '@hofy/api-shared';
import { Box, FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { ProductItem } from '../../../../components/domain/products/ProductItem';
import { ContractLinkLabel } from '../../../contractsPage/ContractLink';
import { ItemLocationCell } from '../../../itemsPage/components/ItemLocationCell';
import { ItemDetailsLink } from '../../../itemsPage/ItemDetailsLink';
import { OrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';
import { UserDetailsLink } from '../../../usersPage/UserDetailsLink';

interface RepairDetailsTabProps {
    repair: RepairDetailsDto;
}

export const RepairDetailsTab: FC<RepairDetailsTabProps> = ({ repair }) => {
    return (
        <Box column gap={40}>
            <RepairDetailsSection repair={repair} />
            <RepairItemDetailsSection repair={repair} />
            <AuditSection model={repair} />
        </Box>
    );
};

const RepairDetailsSection: FC<RepairDetailsTabProps> = ({ repair }) => {
    return (
        <FormSection label='Repair details'>
            <FormGridRow columns={4}>
                <LabeledText label='Id' content={`${repair.id}`} />
                <LabeledText label='Public Id' content={`${repair.publicId}`} />
                {repair.user && (
                    <>
                        <LabeledText
                            label='Requested by'
                            content={
                                <UserDetailsLink id={repair.user.id}>
                                    {formatAdminName(repair.user)}
                                </UserDetailsLink>
                            }
                        />
                        <LabeledText
                            label='Organization'
                            content={
                                repair.organization && (
                                    <OrganizationLink id={repair.organization.id}>
                                        {repair.organization.name}
                                    </OrganizationLink>
                                )
                            }
                        />
                        <LabeledText
                            label='Subcontract Id'
                            content={<ContractLinkLabel id={repair.contract} />}
                        />
                    </>
                )}
                {!repair.user && repair.warehouse && (
                    <LabeledText label='Requested by' content={`${repair.warehouse.name}`} />
                )}
            </FormGridRow>
        </FormSection>
    );
};

const RepairItemDetailsSection: FC<RepairDetailsTabProps> = ({ repair }) => {
    return (
        <FormSection label='Item details'>
            <Box row wrap gap={40} alignItems='flex-start'>
                <LabeledText
                    label='Id'
                    content={<ItemDetailsLink id={repair.item.id}>{`#${repair.item.id}`}</ItemDetailsLink>}
                />
                <Box flex={2}>
                    <ProductItem
                        image={repair.item.variant.image}
                        label={repair.item.product.name}
                        subLabel={formatVariant(repair.item.variant)}
                    />
                </Box>
                <Box flex={1}>
                    <ItemLocationCell location={repair.item.location} />
                </Box>
            </Box>
        </FormSection>
    );
};
