import { useHistory } from 'react-router-dom';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { UUID } from '@hofy/global';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminInvoicingTab } from '../types/AdminInvoicingTab';
import { BillingEntityTab } from './types/BillingEntityTab';

export enum BillingEntityNavigationMethod {
    Create = 'create',
    Edit = 'edit',
}

const billingEntitiesNavLink = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.BillingEntities}`;

export const useNavigateBillingEntity = (baseUrl = billingEntitiesNavLink) => {
    const history = useHistory();
    const { hasPermission } = useSession();

    const hasViewPermission = hasPermission(Permission.AdminBillingEntityView);
    const hasCreateUpdatePermission = hasPermission(Permission.AdminOrganizationUpdateFinancialSettings);

    const navigateBillingEntityList = () => hasViewPermission && history.push(baseUrl);

    const navigateBillingEntityDetails = (id: UUID) =>
        hasViewPermission && history.push(`${baseUrl}/${id}/${BillingEntityTab.Details}`);

    const navigateUpdateBillingEntity = (id: UUID) =>
        hasCreateUpdatePermission && history.push(`${baseUrl}/${id}/${BillingEntityNavigationMethod.Edit}`);

    const navigateBillingEntityTab = (id: UUID, tab: BillingEntityTab) =>
        hasViewPermission && history.push(`${baseUrl}/${id}/${tab}`);

    const getCreateBillingEntityLink = () => `${baseUrl}/${BillingEntityNavigationMethod.Create}`;

    const getBillingEntityNavLink = () => baseUrl;

    return {
        navigateBillingEntityList,
        navigateBillingEntityDetails,
        getCreateBillingEntityLink,
        navigateUpdateBillingEntity,
        navigateBillingEntityTab,
        getBillingEntityNavLink,
    };
};
