import React, { FC } from 'react';

import { allSubscriptionTerms, SubscriptionPayload, useTrSubscriptionTerm } from '@hofy/api-admin';
import { allPaymentSchemasWithoutDeposit, PaymentSchema, useTrPaymentSchema } from '@hofy/api-shared';
import { FormDateInput, FormPriceInput, FormSelect, UseForm } from '@hofy/ui';

interface SubscriptionFormProps {
    form: UseForm<SubscriptionPayload>;
    organizationBased: boolean;
    readOnly?: boolean;
}

export const SubscriptionFormFields: FC<SubscriptionFormProps> = ({ form, organizationBased, readOnly }) => {
    const trBillingFrequency = useTrPaymentSchema();
    const trTerm = useTrSubscriptionTerm();
    const allowedFrequencies = organizationBased ? allPaymentSchemasWithoutDeposit : [PaymentSchema.Monthly];

    return (
        <>
            <FormDateInput label='Starts on' api={form.fields.startOn} disabled={readOnly} />
            <FormSelect
                label='Subscription term'
                api={form.fields.term}
                options={allSubscriptionTerms}
                toText={trTerm}
                isRequired
                disabled={readOnly}
            />
            <FormSelect
                label='Billing frequency'
                api={form.fields.billingFrequency}
                options={allowedFrequencies}
                toText={trBillingFrequency}
                isRequired
                disabled={readOnly}
            />
            <FormPriceInput label='Price' api={form.fields.price} isRequired disabled={readOnly} />
        </>
    );
};
