import { allSubscriptionTerms, SubscriptionDto, SubscriptionPayload } from '@hofy/api-admin';
import { allPaymentSchemasWithoutDeposit } from '@hofy/api-shared';
import { isOneOf, isRequired, useForm, validator } from '@hofy/ui';

export const useSubscriptionForm = (
    onSubmit: (payload: SubscriptionPayload) => void,
    initial: SubscriptionDto,
) => {
    return useForm<SubscriptionPayload>({
        initial: {
            price: initial.price,
            billingFrequency: initial.billingFrequency,
            term: initial.term,
            startOn: initial.startOn,
        },
        onSubmit: onSubmit,
        validate: validator<SubscriptionPayload>({
            price: [isRequired('Price is required')],
            billingFrequency: [
                isRequired('Please select a billing frequency'),
                isOneOf(allPaymentSchemasWithoutDeposit, 'Invalid billing frequency'),
            ],
            term: [
                isRequired('Please select a subscription term'),
                isOneOf(allSubscriptionTerms, 'Invalid subscription term'),
            ],
        }),
    });
};
