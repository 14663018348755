import { emptyListProductsOptions, ListProductsOptions } from '@hofy/api-admin';
import {
    ParentProductCategory,
    ProductCategory,
    useTrParentProductCategory,
    useTrProductCategory,
} from '@hofy/api-shared';
import { AnyRegion, Country, UUID } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti, useFilters } from '@hofy/ui';

import { useBooleanFilterDefinition } from '../filters/useBooleanFilterDefinition';
import { useMultiEnumFilterDefinition } from '../filters/useMultiEnumFilterDefinition';
import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';
import { useTrAvailability } from './useTrAvailability';

interface UseProductFiltersOptions {
    defaultValues: Partial<ListProductsOptions>;
}

export const useProductFilters = (options?: UseProductFiltersOptions) => {
    const search = useSearchFilterDefinition();

    const isActiveForNewOrders = useBooleanFilterDefinition({ name: 'Active for new orders' });
    const isActiveForStoreAndReuse = useBooleanFilterDefinition({ name: 'Active for store and reuse' });
    const isInternal = useBooleanFilterDefinition({ name: 'Internal' });
    const isRefurbished = useBooleanFilterDefinition({ name: 'Refurbished' });

    const excludedProductIds: FilterDefinitionMulti<UUID> = {
        type: 'multi',
        name: 'Excluded products',
        query: queryStringArrayParser(),
    };
    const organizationIds: FilterDefinitionMulti<UUID> = {
        type: 'multi',
        name: 'Organizations',
        query: queryStringArrayParser(),
    };
    const countries: FilterDefinitionMulti<Country> = {
        type: 'multi',
        name: 'Country',
        query: queryStringArrayParser(),
        toLabel: useTrCountry(),
    };
    const region: FilterDefinitionMulti<AnyRegion> = {
        type: 'multi',
        name: 'Region',
        query: queryStringArrayParser(),
        toLabel: useTrAvailability(),
    };

    const categories = useMultiEnumFilterDefinition({
        name: 'Category',
        statusEnum: ProductCategory,
        toLabel: useTrProductCategory(),
    });
    const parentCategories = useMultiEnumFilterDefinition({
        name: 'Parent category',
        statusEnum: ParentProductCategory,
        toLabel: useTrParentProductCategory(),
    });

    return useFilters<ListProductsOptions>(
        {
            search,
            categories,
            parentCategories,
            countries,
            regions: region,
            isActiveForNewOrders,
            isActiveForStoreAndReuse,
            isInternal,
            isRefurbished,
            excludedProductIds,
            organizationIds,
        },
        {
            ...emptyListProductsOptions,
            ...options?.defaultValues,
        },
    );
};
