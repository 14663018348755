import React, { FC } from 'react';

import { ItemRefDto, useTrOwnership } from '@hofy/api-shared';
import { FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { ItemDetailsLink } from '../../../itemsPage/ItemDetailsLink';

interface MainItemSectionProps {
    item: ItemRefDto | null;
}

export const MainItemSection: FC<MainItemSectionProps> = ({ item }) => {
    const trOwnership = useTrOwnership();
    if (!item) {
        return null;
    }
    return (
        <FormSection label='Main item'>
            <FormGridRow columns={2}>
                <LabeledText
                    label='Id'
                    content={<ItemDetailsLink id={item.id}>{`${item.publicId}`}</ItemDetailsLink>}
                />
                <LabeledText label='Serial number' content={item.serialNumber} />
                <LabeledText label='Ownership' content={trOwnership(item.ownership)} />
            </FormGridRow>
        </FormSection>
    );
};
