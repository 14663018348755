import React, { FC } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { AssignmentDetailsLink } from '../assignmentsPage/AssignmentDetailsLink';
import { ContractLink } from '../contractsPage/ContractLink';
import { ItemDetailsLink } from '../itemsPage/ItemDetailsLink';
import { OrganizationLink } from '../organizationsPage/OrganizationDetailsLink';

interface AuditLogsDetailsEntityLinkProps {
    tableName?: string;
    tableId?: number;
    tableUuid?: UUID;
}

export const AuditLogsDetailsEntityLink: FC<AuditLogsDetailsEntityLinkProps> = ({
    tableName,
    tableUuid,
    tableId,
}) => {
    if (!tableName || !tableId || tableUuid) {
        return <>--</>;
    }

    const content = () => {
        return `#${tableId}`;
    };

    switch (tableName) {
        case AuditableTable.Assignment:
            return <AssignmentDetailsLink id={tableUuid!}>{content()}</AssignmentDetailsLink>;
        case AuditableTable.Organization:
            return <OrganizationLink id={tableUuid!}>{content()}</OrganizationLink>;
        case AuditableTable.Item:
            return <ItemDetailsLink id={tableUuid!}> {content()}</ItemDetailsLink>;
        case AuditableTable.RentalContracts:
            return <ContractLink id={tableUuid!}>{content()}</ContractLink>;
    }
    return <>--</>;
};
