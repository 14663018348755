import React, { FC, useState } from 'react';

import { BYODOrderDto } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { ConfirmModal, Paragraph3, Span, SvgIcon } from '@hofy/ui';

import { useCancelBYODOrder } from '../../../../store/byodOrders/useCancelBYODOrder';

interface BYODOrderActionMenuProps {
    onReceiveBYODOrder(id: UUID): void;
    byodOrder: BYODOrderDto;
}
enum ModalType {
    CancelBYODOrder = 'CancelBYODOrder',
}

export const BYODOrderActionMenu: FC<BYODOrderActionMenuProps> = ({ onReceiveBYODOrder, byodOrder }) => {
    const [modal, setModal] = useState<ModalType>();
    const closeModal = () => setModal(undefined);

    const { mutate: onCancelBYODOrder } = useCancelBYODOrder(byodOrder.id);

    const { hasPermission } = useSession();
    if (!hasPermission(Permission.AdminBYODOrdersReceive)) {
        return null;
    }

    return (
        <>
            <MoreMenu
                items={[
                    {
                        label: 'Receive',
                        icon: SvgIcon.Package,
                        action: () => onReceiveBYODOrder(byodOrder.id),
                        visible: byodOrder.isReceiveAllowed,
                    },
                    {
                        label: 'Cancel',
                        icon: SvgIcon.Trash,
                        action: () => setModal(ModalType.CancelBYODOrder),
                        visible: byodOrder.isCancelAllowed,
                    },
                ]}
            />

            {modal === ModalType.CancelBYODOrder && (
                <ConfirmModal
                    keyPrefix='cancel-byod-order-modal'
                    onConfirm={onCancelBYODOrder}
                    onClose={closeModal}
                    action='destructive'
                >
                    <Paragraph3 marginTop={10} color={Color.ContentPrimary}>
                        BYOD Order: <Span bold>{`#${byodOrder.publicId}`}</Span>
                    </Paragraph3>
                </ConfirmModal>
            )}
        </>
    );
};
