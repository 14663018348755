import React, { FC, useState } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import {
    isPossibleStatusTransition,
    isShipmentFromOrganizationWarehouse,
    isShipmentFromUser,
    isShipmentFromUserToUser,
    isWithCourierOrCompletedOrCanceled,
    Permission,
    ShipmentLocationAware,
    ShipmentStatus,
    ShipmentTrackingDto,
    ShipmentTrackingExceptionStatus,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, BoxProps, ConfirmModal, Modals, SvgIcon } from '@hofy/ui';

import { useAuditLogMenuOption } from '../../../store/auditLogs/useAuditMenuOption';
import { useClearTrackingExceptionRequest } from '../../../store/shipments/useClearTrackingExceptionRequest';
import { useCreateCollectionRequestSurvey } from '../../../store/shipments/useCreateCollectionRequestSurvey';
import { useEnableTrackingRequest } from '../../../store/shipments/useEnableTrackingRequest';
import { SendCollectionSurveyForm } from '../../../views/assignmentsPage/components/SendCollectionSurveyForm';
import { ShipmentAction } from '../../../views/shipmentSlideout/ShipmentAction';

enum ShipmentModal {
    UpdateShipmentDetails,
    CreateCollectionSurvey,
    ClearTrackingError,
    EnablingTracking,
}

interface ShipmentMenuProps extends BoxProps {
    shipment: ShipmentLocationAware & {
        id: UUID;
        status: ShipmentStatus;
        tracking: ShipmentTrackingDto | null;
        trackingExceptionStatus: ShipmentTrackingExceptionStatus | null;
    };
    isCollectionSurveyCreated: boolean;
    onAction(action: ShipmentAction): void;
}

export const ShipmentMenu: FC<ShipmentMenuProps> = shipmentProps => {
    const {
        shipment: { id, status, tracking, trackingExceptionStatus },
        isCollectionSurveyCreated,
        onAction,
        ...boxProps
    } = shipmentProps;
    const { hasPermission } = useSession();
    const canChangeShipment = hasPermission(Permission.AdminShipmentsUpdate);

    const [modal, setModal] = useState<ShipmentModal>();
    const [menuLink] = useAuditLogMenuOption(AuditableTable.Shipment, id);
    const { form } = useCreateCollectionRequestSurvey(id);
    const { mutate: clearTrackingException } = useClearTrackingExceptionRequest(id);
    const { mutate: enableTracking } = useEnableTrackingRequest(id);

    const updateShipmentDetails = () => {
        if (status === ShipmentStatus.WithCourier) {
            setModal(ShipmentModal.UpdateShipmentDetails);
            return;
        }

        onAction(ShipmentAction.Edit);
    };

    const menuItems = [
        {
            icon: SvgIcon.Cross,
            action: () => onAction(ShipmentAction.Cancel),
            label: 'Cancel shipment',
            testKey: 'cancel-shipment',
            visible:
                !(
                    isShipmentFromUserToUser(shipmentProps.shipment) && status === ShipmentStatus.WithCourier
                ) &&
                isPossibleStatusTransition(status, ShipmentStatus.Canceled) &&
                canChangeShipment,
        },
        {
            icon: SvgIcon.Truck,
            action: updateShipmentDetails,
            label: 'Update shipment details',
            testKey: 'edit-courier-details',
            visible:
                [
                    ShipmentStatus.Created,
                    ShipmentStatus.Booked,
                    ShipmentStatus.Backorder,
                    ShipmentStatus.WithCourier,
                ].includes(status) && canChangeShipment,
        },
        {
            icon: SvgIcon.MessageCheck,
            action: () => setModal(ShipmentModal.ClearTrackingError),
            label: 'Acknowledge tracking exception',
            testKey: 'clear-tracking-exception',
            visible: trackingExceptionStatus === ShipmentTrackingExceptionStatus.Raised && canChangeShipment,
        },
        {
            icon: SvgIcon.Zap,
            action: () => setModal(ShipmentModal.EnablingTracking),
            label: 'Enable shipment tracking',
            testKey: 'enable-tracking',
            visible: !tracking && canChangeShipment,
        },
        {
            icon: SvgIcon.Send,
            action: () => setModal(ShipmentModal.CreateCollectionSurvey),
            label: 'Create collection survey',
            testKey: 'create-collection-survey',
            visible:
                !isWithCourierOrCompletedOrCanceled(status) &&
                (isShipmentFromUser(shipmentProps.shipment) ||
                    isShipmentFromOrganizationWarehouse(shipmentProps.shipment)) &&
                !isCollectionSurveyCreated,
        },
        menuLink,
    ];

    return (
        <Box {...boxProps}>
            <MoreMenu items={menuItems} />
            <Modals>
                {modal === ShipmentModal.UpdateShipmentDetails && (
                    <ConfirmModal
                        keyPrefix='item-assignment-shipment-details-update-modal'
                        onClose={() => setModal(undefined)}
                        onConfirm={() => onAction(ShipmentAction.Edit)}
                    />
                )}
                {modal === ShipmentModal.CreateCollectionSurvey && (
                    <ConfirmModal
                        keyPrefix='item-assignment-shipment-create-survey-modal'
                        onClose={() => setModal(undefined)}
                        onConfirm={form.submit}
                    >
                        <Box column gap={8} paddingVertical={16}>
                            <SendCollectionSurveyForm form={form} />
                        </Box>
                    </ConfirmModal>
                )}
                {modal === ShipmentModal.ClearTrackingError && (
                    <ConfirmModal
                        keyPrefix='item-assignment-shipment-clear-tracking-exception-modal'
                        onClose={() => setModal(undefined)}
                        onConfirm={() => clearTrackingException()}
                    />
                )}
                {modal === ShipmentModal.EnablingTracking && (
                    <ConfirmModal
                        keyPrefix='item-assignment-shipment-enable-tracking-modal'
                        onClose={() => setModal(undefined)}
                        onConfirm={() => enableTracking()}
                    />
                )}
            </Modals>
        </Box>
    );
};
