import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { BYODOrdersFilter } from '../store/BYODOrdersFilter';
import { BYODOrderDto } from './types/BYODOrderDto';
import { BYODOrderPayload } from './types/BYODOrderPayload';
import { ReceiveBYODOrderItemPayload } from './types/ReceiveBYODOrderItemPayload';

class BYODOrderService {
    public createBYODOrder = (payload: BYODOrderPayload): Promise<UUID> => {
        return restClient
            .postJson<{
                id: UUID;
            }>('/api/admin/byod-orders', payload)
            .then(r => r.id);
    };

    public getBYODOrder = (id: UUID): Promise<BYODOrderDto> => {
        return restClient.getJson<BYODOrderDto>(`/api/admin/byod-orders/${id}`);
    };

    public listBYODOrders = (
        filters: BYODOrdersFilter,
        page: PageRequest,
    ): Promise<PageResponse<BYODOrderDto>> => {
        return restClient.getJson<PageResponse<BYODOrderDto>>(
            stringifyUrl({
                url: `/api/admin/byod-orders`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public cancelBYODOrder = async (id: UUID): Promise<void> => {
        await restClient.post(`/api/admin/byod-orders/${id}/cancel`, undefined);
    };

    public receiveBYODOrderItem = async (
        byodOrderItemId: UUID,
        payload: ReceiveBYODOrderItemPayload,
    ): Promise<void> => {
        await restClient.post(`/api/admin/byod-order-items/${byodOrderItemId}/receive`, payload);
    };
}

export const byodOrderService = new BYODOrderService();
