import React, { FC } from 'react';

import { AssignmentWithProductDto } from '@hofy/api-admin';
import { useTrProductCategory } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { BaseTable, Placeholder, PublicIdColumn, SvgIllustration } from '@hofy/ui';
import { ItemOwnershipBadge } from '@hofy/ui-domain';

import { AssignmentCompletionReasonBadge } from '../../../components/domain/assignments/./badge/AssignmentCompletionReasonBadge';
import { AssignmentTypeChip } from '../../assignmentsPage/components/AssignmentTypeChip';

interface AssignmentsTableProps {
    assignments: AssignmentWithProductDto[];
    isLoading: boolean;
    onOpenAssignment(id: UUID): void;
}

export const AssignmentsTable: FC<AssignmentsTableProps> = ({
    assignments: adminOrders,
    onOpenAssignment,
    isLoading,
}) => {
    const trProductCategory = useTrProductCategory();

    return (
        <BaseTable
            data={adminOrders}
            toKey={order => order.id}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.OrderSearch}
                    title='No assignments'
                    message='No assignments for selected criteria'
                />
            }
            isLoading={isLoading}
            onRowClick={v => onOpenAssignment(v.id)}
            columns={[
                PublicIdColumn<AssignmentWithProductDto>(),
                {
                    id: 'product-name',
                    header: 'Product name',
                    flexGrow: 2,
                    renderer: order => order.product.name,
                },
                {
                    id: 'product-brand',
                    header: 'Product brand',
                    renderer: order => order.product.brand,
                },
                {
                    id: 'product-category',
                    header: 'Product category',
                    renderer: order => trProductCategory(order.product.category),
                },
                {
                    id: 'ownership',
                    header: 'Item Ownership',
                    renderer: ({ item }) =>
                        item ? <ItemOwnershipBadge ownership={item?.ownership} /> : '--',
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: order => (
                        <AssignmentTypeChip
                            status={order.status}
                            exception={order.exception}
                            collectionReason={order.collectionReason}
                        />
                    ),
                },
                {
                    id: 'completion-reason',
                    header: 'Completion reason',
                    renderer: assignment =>
                        assignment.exception ? (
                            <AssignmentCompletionReasonBadge reason={assignment.exception} />
                        ) : (
                            '--'
                        ),
                },
            ]}
        />
    );
};
