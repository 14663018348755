import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAddressValidator } from '@hofy/address';
import {
    emptyHofyWarehousePayload,
    HofyWarehousePayload,
    useHofyWarehouseQuery,
    warehouseCacheKey,
    warehouseService,
} from '@hofy/api-admin';
import { addressToPayloadWithDefault, AddressType } from '@hofy/api-shared';
import { UUID, zeroUuid } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { isRequired, UseForm, useForm, useToast, validator } from '@hofy/ui';

interface CreateUpdateHofyWarehouseState {
    form: UseForm<HofyWarehousePayload>;
    isLoading: boolean;
    isError: boolean;
}

export const useCreateUpdateHofyWarehouse = (warehouseId?: UUID): CreateUpdateHofyWarehouseState => {
    const { isLoading: isLoadingData, data: warehouseDto } = useHofyWarehouseQuery(warehouseId || zeroUuid);

    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const { goBack } = useGoBack();

    const mutation = useMutation({
        mutationFn: (payload: HofyWarehousePayload) =>
            warehouseService.createUpdateHofyWarehouse(payload, warehouseId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [warehouseCacheKey] });
            showToast({
                type: 'positive',
                message: 'Warehouse successfully saved',
            });
            goBack();
        },
    });

    const validateAddress = useAddressValidator();
    const form = useForm<HofyWarehousePayload>({
        initialDeps: [warehouseDto],
        initial: warehouseDto
            ? {
                  ...emptyHofyWarehousePayload,
                  name: warehouseDto.name,
                  address: addressToPayloadWithDefault(warehouseDto.address, AddressType.Billing),
                  type: warehouseDto.hofyWarehouse.type,
                  hofySubsidiaryId: warehouseDto.hofyWarehouse.hofySubsidiaryId,
                  countries: warehouseDto.hofyWarehouse.countries,
                  dataErasureSupported: warehouseDto.hofyWarehouse.dataErasureSupported,
              }
            : emptyHofyWarehousePayload,
        onSubmit: mutation.mutate,
        validate: validator<HofyWarehousePayload>({
            hofySubsidiaryId: isRequired('Hofy Subsidiary is required'),
            type: isRequired('Warehouse type is required'),
            name: isRequired('Warehouse name is required'),
            address: validateAddress,
        }),
    });

    return {
        form: form,
        isLoading: mutation.isPending || isLoadingData,
        isError: mutation.isError,
    };
};
