import React, { FC } from 'react';

import { useSubscription } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { isPositive } from '@hofy/global';
import { UUID } from '@hofy/global';
import { Skeleton } from '@hofy/ui';

import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';
import { ReplaceSubscriptionSlideout } from './ReplaceSubscriptionSlideout';
import { UpdateSubscriptionSlideout } from './UpdateSubscriptionSlideout';

interface UpdateSubscriptionSlideoutContentProps {
    organizationId: UUID;
    subscriptionId: UUID;
    onClose(): void;
}

export const UpdateSubscriptionSlideoutContainer: FC<UpdateSubscriptionSlideoutContentProps> = ({
    organizationId,
    subscriptionId,
    onClose,
}) => {
    const { hasPermission } = useSession();
    const { data: organization, isLoading: organizationIsLoading } =
        useOrganizationDetailsQuery(organizationId);
    const { data: subscription, isLoading: subscriptionIsLoading } = useSubscription(
        organizationId,
        subscriptionId,
    );

    if (organizationIsLoading || subscriptionIsLoading || !organization || !subscription) {
        return <Skeleton />;
    }

    if (
        hasPermission(Permission.AdminOrganizationReplaceSubscription) &&
        isPositive(subscription.invoiceEntriesTotalValue)
    ) {
        return (
            <ReplaceSubscriptionSlideout
                organization={organization}
                subscription={subscription}
                onClose={onClose}
            />
        );
    }

    return (
        <UpdateSubscriptionSlideout
            organization={organization}
            subscription={subscription}
            onClose={onClose}
        />
    );
};
