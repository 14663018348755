import { omit } from 'lodash';
import { useMemo } from 'react';

import { AdminItemFilter, ItemsFilters } from '@hofy/api-admin';
import {
    anyCategoryToList,
    AnyProductCategory,
    ItemGrade,
    ItemLocation,
    ItemStatus,
    Ownership,
} from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

export interface ItemsFiltersUi {
    warehouseId: UUID | null;
    grade: ItemGrade[];
    organization: UUID[];
    search: string | null;
    category: AnyProductCategory | null;
    variant: UUID[];
    redistributableIn: Country | null;
    status: ItemStatus[];
    location: ItemLocation[];
    filter: AdminItemFilter | null;
    ownership: Ownership | null;
}

export const toItemsFilters = ({ category, ...rest }: ItemsFiltersUi): ItemsFilters => {
    return {
        ...rest,
        category: category ? anyCategoryToList(category) : [],
    };
};

export const useAdminItemFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');

    const [organization, setOrganization] = useArrayQueryParam<UUID>('organization');
    const [category, setCategory] = useStringQueryParam<AnyProductCategory>('category');
    const [warehouseId, setWarehouseId] = useStringQueryParam<UUID>('warehouseId');
    const [grade, setGrade] = useArrayQueryParam<ItemGrade>('grade');
    const [itemStatus, setItemStatus] = useArrayQueryParam<ItemStatus>('itemStatus');
    const [location, setLocation] = useArrayQueryParam<ItemLocation>('location');
    const [ownership, setOwnership] = useStringQueryParam<Ownership>('ownership');
    const [filter, setFilterType] = useStringQueryParam<AdminItemFilter>('filter');

    const filtersUi = useStructMemo<ItemsFiltersUi>({
        search,
        organization,
        category,
        grade,
        filter,
        location,
        status: itemStatus,
        warehouseId: warehouseId,
        ownership,
        variant: [],
        redistributableIn: null,
    });

    const filters = useMemo(() => toItemsFilters(filtersUi), [filtersUi]);

    const filtersToCount = omit(filters, 'search', 'category');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filtersUi,
        filterCount,
        setSearch,
        setOrganization,
        setCategory,
        setGrade,
        setItemStatus,
        setWarehouseId,
        setLocation,
        setFilterType,
        setOwnership,
    };
};
