import React, { FC } from 'react';

import { AssignmentDetailsDto } from '@hofy/api-admin';
import { Box, FormSection, LabeledText } from '@hofy/ui';

import { AssignmentDetailsLink } from '../../assignmentsPage/AssignmentDetailsLink';
import { ItemDetailsLink } from '../../itemsPage/ItemDetailsLink';
import { OrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';

interface DataErasureSlideoutDetailsProps {
    assignment: AssignmentDetailsDto;
}

export const DataErasureSlideoutDetails: FC<DataErasureSlideoutDetailsProps> = ({ assignment }) => {
    const itemDetailsLink = assignment.item ? (
        <ItemDetailsLink id={assignment.item?.id}>#{assignment.item?.id}</ItemDetailsLink>
    ) : (
        `--`
    );

    return (
        <Box>
            <FormSection paddingBottom={20} label='Details'>
                <Box row>
                    <LabeledText
                        flex={1}
                        label='Assignment ID'
                        content={
                            <AssignmentDetailsLink id={assignment.id}>
                                #{assignment.publicId}
                            </AssignmentDetailsLink>
                        }
                    />
                    <LabeledText flex={1} label='Item ID' content={itemDetailsLink} />
                </Box>
                <Box row>
                    <LabeledText
                        flex={1}
                        label='Organisation name'
                        content={
                            <OrganizationLink id={assignment.organization.id}>
                                {assignment.organization.name}
                            </OrganizationLink>
                        }
                    />
                </Box>
            </FormSection>
        </Box>
    );
};
