export const addonItemI18n = {
    'addon-item.hardware-support': 'Hardware support',
    'addon-item.managed-repair': 'Managed repair',
    'addon-item.loaner': 'Loaner',
    'addon-item.repair-logistics': 'Out-of-warranty repair',
    'addon-item.replacement': 'Replacement',
    'addon-item.local-store-and-reuse': 'Store and reuse in the same region',
    'addon-item.hub-store-and-reuse': 'Store and reuse across regions',
    'addon-item.transfer': 'Transfer',
    'addon-item.clearance': 'Device clearance',
    'addon-item.device-config': 'Device pre-configuration',
    'addon-item.global-warranty': 'Global warranty',
    'addon-item.platform-fee-operations': 'Platform fee operations',
    'addon-item.description.hardware-support': '24/7 hardware support',
    'addon-item.description.managed-repair': 'Fully managed repair service',
    'addon-item.description.loaner': 'Global loaner to maximise employee uptime',
    'addon-item.description.repair-logistics': 'Out-of-warranty repair service',
    'addon-item.description.replacement': 'Replacements of damaged devices beyond repair',
    'addon-item.description.local-store-and-reuse': 'Store and reuse in the same region',
    'addon-item.description.hub-store-and-reuse': 'Store and reuse across regions',
    'addon-item.description.transfer': 'Transfers betweens users and storage locations',
    'addon-item.description.clearance': 'Clearance of old devices',
    'addon-item.description.device-config': 'Device pre-configuration',
    'addon-item.description.global-warranty': 'Global warranty',
    'addon-item.description.platform-fee-operations': 'Access, support and maintenance of cloud services',
};
