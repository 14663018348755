import React, { FC } from 'react';

import {
    AssignmentsPageTab,
    useAdminsQuery,
    useHofyWarehousesQuery,
    UserAssignmentDto,
} from '@hofy/api-admin';
import {
    allOwnerships,
    allShipmentTypes,
    Ownership,
    Role,
    ShipmentType,
    useTrOwnership,
    useTrShipmentType,
} from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { Country, UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    ActiveFilters,
    ActiveFiltersLayout,
    Box,
    DropdownList,
    FilterSearchInput,
    LabeledSwitch,
    PageHeader,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumBlockFilter } from '../../components/design/blockFilters/EnumBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { UsersBlockFilter } from '../../components/domain/user/UsersBlockFilter';
import { WarehouseBlockFilter } from '../../components/domain/warehouses/WarehouseBlockFilter';
import { useAdminI18n } from '../../i18n/useAdminI18n';
import { useAdminAssignmentsFilters } from '../../store/assignments/useAdminAssignmentsFilters';
import { SortOptions, useAdminAssignmentsSorting } from '../../store/assignments/useAdminAssignmentsSortings';
import { UserAssignmentSelectionRecord } from '../../store/assignments/useAssignmentsSelection';
import { AssignmentsPageTabs } from './AssignmentsPageTabs';
import { AssignmentFiltersBlock } from './components/filters/blocks/AssignmentFiltersBlock';
import { AssignmentsDateFilter } from './components/filters/blocks/AssignmentsDateFilter';
import { CollectionSurveyStatusBlockFilter } from './components/filters/blocks/CollectionSurveyStatusBlockFilter';
import { CountryBlockFilter } from './components/filters/blocks/CountryBlockFilter';
import { RegionBlockFilter } from './components/filters/blocks/RegionBlockFilter';
import { ShipmentStatusBlockFilter } from './components/filters/blocks/ShipmentStatusBlockFilter';
import { UsersShipments } from './components/users/UsersShipments';

interface AssignmentsPageProps {
    activeTab: AssignmentsPageTab;
    onTabChanged(tab: AssignmentsPageTab): void;
    selectedAssignments: Record<UUID, UserAssignmentSelectionRecord>;
    onToggleSelectedAssignments(a: UserAssignmentDto[]): void;
    onCreateShipment(userId: UUID, organizationId: UUID, country: Country, shipmentType: ShipmentType): void;
    onInPersonTransfer(userId: UUID, organizationId: UUID, country: Country): void;
}

export const AssignmentsPage: FC<AssignmentsPageProps> = ({
    activeTab,
    onTabChanged,
    selectedAssignments,
    onToggleSelectedAssignments,
    onCreateShipment,
    onInPersonTransfer,
}) => {
    const { trEnum } = useAdminI18n();
    const trShipmentType = useTrShipmentType();
    const trOwnership = useTrOwnership();
    const trSortOption = trEnum<SortOptions>(SortOptions, 'admin-order-sort-option');

    const { data: admins } = useAdminsQuery(Role.FulfillmentAdmin, true);
    const { data: warehouses } = useHofyWarehousesQuery();

    const isAllTab = activeTab === AssignmentsPageTab.All;
    const { filterValues, filters, filterCount, clearFilters } = useAdminAssignmentsFilters({
        defaultValues: {
            futureScheduled: isAllTab || null,
            isPending: !isAllTab,
        },
        assignedUsersList: admins,
        warehousesList: warehouses,
    });

    const { sort, sortingOptions, handleSort } = useAdminAssignmentsSorting();

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Page pageId='admin-assignments'>
            <PageHeader
                title='Assignments'
                rightSlot={
                    <Box gap={20} flex={1} justify='flex-end' row>
                        <Box row>
                            <Paragraph3 bold marginRight={4} color={Color.ContentPrimary}>
                                Sort:
                            </Paragraph3>
                            <DropdownList
                                options={sortingOptions}
                                value={sort}
                                onChange={handleSort}
                                toText={sort => trSortOption(sort.sortBy)}
                            />
                        </Box>
                        <LabeledSwitch
                            label='Completed'
                            checked={filterValues.isPending === false}
                            disabled={!isCompletedSwitchEnabled(activeTab)}
                            onChange={value => filters.isPending.set(!value)}
                        />
                        <LabeledSwitch
                            label='Scheduled'
                            checked={!!filterValues.futureScheduled}
                            disabled={activeTab === AssignmentsPageTab.All}
                            onChange={filters.futureScheduled.set}
                        />
                        <FilterSearchInput
                            filter={filters.search}
                            placeholder='Search customer, assignment, ...'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </Box>
                }
                tabsSlot={<AssignmentsPageTabs tab={activeTab} onChange={onTabChanged} />}
            />
            <BlockFilterContainer show={showFilters} ref={filterElRef}>
                <UsersBlockFilter
                    selected={filterValues.assignedUsers}
                    onChange={filters.assignedUsers.set}
                    users={admins}
                />
                <EnumBlockFilter<ShipmentType>
                    title='Shipment type'
                    icon={SvgIcon.Box}
                    selected={filterValues.shipmentType}
                    onChange={filters.shipmentType.set}
                    items={allShipmentTypes}
                    renderItem={type => trShipmentType(type)}
                />
                <ShipmentStatusBlockFilter
                    selected={filterValues.shipmentStatus}
                    onChange={filters.shipmentStatus.set}
                />
                <CollectionSurveyStatusBlockFilter
                    selected={filterValues.collectionSurveyStatus}
                    onChange={filters.collectionSurveyStatus.set}
                />
                <RegionBlockFilter selected={filterValues.region} onChange={filters.region.set} />
                <CountryBlockFilter selected={filterValues.countries} onChange={filters.countries.set} />
                <WarehouseBlockFilter
                    warehouses={warehouses}
                    selected={filterValues.warehouses}
                    onChange={filters.warehouses.set}
                />
                <EnumBlockFilter<Ownership>
                    title='Ownership'
                    icon={SvgIcon.Box}
                    selected={filterValues.ownership}
                    onChange={filters.ownership.set}
                    items={allOwnerships}
                    renderItem={ownership => trOwnership(ownership)}
                />
                <AssignmentFiltersBlock filterApi={filters} filterValues={filterValues} />
                <AssignmentsDateFilter filterApi={filters} filterValues={filterValues} />
            </BlockFilterContainer>
            <Box>
                <ActiveFiltersLayout show={filterCount > 0}>
                    <ActiveFilters filters={filters} onClearAll={clearFilters} />
                </ActiveFiltersLayout>
            </Box>
            <UsersShipments
                filters={filterValues}
                tab={activeTab}
                sortOptions={sort}
                selectedAssignments={selectedAssignments}
                onToggleSelectedAssignments={onToggleSelectedAssignments}
                onCreateShipment={onCreateShipment}
                onInPersonTransfer={onInPersonTransfer}
            />
        </Page>
    );
};

// Completed switch

const completedSwitchAvailableTabs = [AssignmentsPageTab.Collection, AssignmentsPageTab.Delivery];
const isCompletedSwitchEnabled = (tab: AssignmentsPageTab): boolean => {
    return completedSwitchAvailableTabs.includes(tab);
};
