import React, { FC } from 'react';

import { useOrdersQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { OrdersTable } from '../../../components/domain/requests/OrdersTable';

interface OrdersTabProps {
    userId: UUID;
    onOpenOrder(id: UUID): void;
}

export const OrdersTab: FC<OrdersTabProps> = ({ userId, onOpenOrder }) => {
    const {
        data: orders,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    } = useOrdersQuery({ userId });

    return (
        <OrdersTable
            orders={orders}
            infinityScroll={{
                isLoading: isLoading,
                hasMore: hasNextPage,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            onOpenOrder={onOpenOrder}
        />
    );
};
