import React, { FC } from 'react';

import { useMessagesByUserQuery, UserDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { MessagesTable } from '../../../components/domain/messages/MessagesTable';

interface UserMessagesTabProps {
    user: UserDto;
    onOpenMessage(messageId: UUID): void;
}

export const UserMessagesTab: FC<UserMessagesTabProps> = ({ user, onOpenMessage }) => {
    const {
        adminMessages,
        adminMessagesIsLoading,
        adminMessagesIsError,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useMessagesByUserQuery(user.id);

    return (
        <MessagesTable
            messages={adminMessages}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: adminMessagesIsLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            onOpenMessage={onOpenMessage}
            isError={adminMessagesIsError}
        />
    );
};
