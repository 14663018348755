import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { formatVariant, ProductDto, VariantDetailsDto } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph2, Paragraph3, SvgIcon } from '@hofy/ui';

import { ProductItem } from '../products/ProductItem';

interface OrderItemListProps extends BoxProps {
    assignments: {
        id: UUID;
        product: ProductDto;
        variant: VariantDetailsDto;
    }[];
    onRemoveAssignment?(item: { id: UUID; product: ProductDto; variant: VariantDetailsDto }): void;
    onOrderClick?(id: UUID): void;
}

export const OrderItemList: FC<OrderItemListProps> = ({
    assignments,
    onRemoveAssignment,
    onOrderClick,
    ...restProps
}) => {
    const itemsLength = assignments.length;
    return itemsLength > 0 ? (
        <Box {...restProps}>
            {assignments.map(order => (
                <RowItem
                    key={order.id}
                    item={order}
                    onRemove={itemsLength === 1 ? undefined : onRemoveAssignment}
                    onClick={onOrderClick}
                >
                    <ProductItem
                        image={order.variant.image?.url}
                        label={order.product.name}
                        subLabel={formatVariant(order.variant)}
                    />
                </RowItem>
            ))}
        </Box>
    ) : (
        <Paragraph2 paddingVertical={10} centered>
            No Items
        </Paragraph2>
    );
};

interface RowItemProps<T> {
    item: T;
    onRemove?(item: T): void;
    children: ReactNode;
    onClick?(id: UUID): void;
}

const RowItem = <T extends { id: UUID }>({ item, onRemove, onClick, children }: RowItemProps<T>) => (
    <RowItemWrapper
        row
        justify='space-between'
        paddingVertical={20}
        hoverable={!!onClick}
        onClick={() => onClick?.(item.id)}
        borderBottom
    >
        <Paragraph3 width={95} color={Color.ContentPrimary}>
            #{item.id}
        </Paragraph3>
        <Box flex={1}>{children}</Box>
        {onRemove && (
            <MoreMenu
                items={[
                    {
                        icon: SvgIcon.Cross,
                        action: () => onRemove(item),
                        label: 'Remove from shipment',
                        testKey: 'remove-from-shipment',
                    },
                ]}
            />
        )}
    </RowItemWrapper>
);

const RowItemWrapper = styled(Box)<{ hoverable: boolean }>`
    padding-left: 10px;
    padding-right: 10px;

    &:last-child {
        border-bottom: none;
    }

    ${props =>
        props.hoverable &&
        `&:hover {
            cursor: pointer;
            background-color: ${Color.BackgroundSubtleNeutral};
        }`}
`;
