import React, { FC, useMemo, useState } from 'react';

import { AuditableTable, usePurchaseOrderQuery } from '@hofy/api-admin';
import { Permission, PurchaseOrderStatus } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { CenteredBox, ComponentLoader, MoreMenu, Overlay, Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, Button, Modals, PageHeader, Placeholder, SvgIcon, SvgIllustration, Tab, Tabs } from '@hofy/ui';

import { useAuditLogMenuOption } from '../../../../store/auditLogs/useAuditMenuOption';
import {
    allPurchaseOrderTabs,
    PurchaseOrderTab,
} from '../../../../store/purchaseOrders/types/PurchaseOrderTab';
import { useTrPurchaseOrderTab } from '../../../../store/purchaseOrders/useTrPurchaseOrderTab';
import { CancelPurchaseOrderModal } from './components/CancelPurchaseOrderModal';
import { ClosePurchaseOrderModal } from './components/ClosePurchaseOrderModal';
import { DiscardPurchaseOrderModal } from './components/DiscardPurchaseOrderModal';
import { PurchaseOrderDetailsTab } from './PurchaseOrderDetailsTab';
import { PurchaseOrderItemsTab } from './PurchaseOrderItemsTab';

interface PurchaseOrderDetailsOverlayProps {
    purchaseOrderId: UUID;
    purchaseOrderTab: PurchaseOrderTab;
    onChangeDetailsTab(tab: PurchaseOrderTab): void;
    handleOpenEditPurchaseOrder(tab: PurchaseOrderTab): void;
    onDeleted(): void;
}

enum PurchaseOrderModal {
    Cancel = 'cancel',
    Close = 'close',
    Discard = 'discard',
}

export const PurchaseOrderDetailsOverlay: FC<PurchaseOrderDetailsOverlayProps> = ({
    purchaseOrderId,
    purchaseOrderTab,
    onChangeDetailsTab,
    handleOpenEditPurchaseOrder,
    onDeleted,
}) => {
    const { data: purchaseOrder, isLoading: purchaseOrderIsLoading } = usePurchaseOrderQuery(purchaseOrderId);
    const trTab = useTrPurchaseOrderTab();
    const [modal, setModal] = useState<PurchaseOrderModal | null>(null);
    const [auditLogsMenu] = useAuditLogMenuOption(AuditableTable.PurchaseOrder, purchaseOrderId);

    const { hasPermission } = useSession();

    const isPurchaseOrderEditable = useMemo(() => {
        if (!hasPermission(Permission.AdminPurchaseOrdersUpdate)) {
            return false;
        }

        const editableStates = [
            PurchaseOrderStatus.Draft,
            PurchaseOrderStatus.PendingApproval,
            PurchaseOrderStatus.Approved,
            PurchaseOrderStatus.Declined,
            PurchaseOrderStatus.PendingReceipt,
        ];
        return !(purchaseOrder && !editableStates.includes(purchaseOrder.status));
    }, [hasPermission, purchaseOrder]);

    const isPurchaseOrderCancellable = useMemo(() => {
        if (!hasPermission(Permission.AdminPurchaseOrdersUpdate)) {
            return false;
        }
        const cancellableStates = [
            PurchaseOrderStatus.Declined,
            PurchaseOrderStatus.Approved,
            PurchaseOrderStatus.PendingApproval,
            PurchaseOrderStatus.PendingReceipt,
        ];
        return !(purchaseOrder && !cancellableStates.includes(purchaseOrder.status));
    }, [hasPermission, purchaseOrder]);

    const isPurchaseOrderClosable = useMemo(() => {
        if (!hasPermission(Permission.AdminPurchaseOrdersUpdate)) {
            return false;
        }
        const cancellableStates = [
            PurchaseOrderStatus.PartiallyBilled,
            PurchaseOrderStatus.PartiallyBilledAndReceived,
        ];
        return !(purchaseOrder && !cancellableStates.includes(purchaseOrder.status));
    }, [hasPermission, purchaseOrder]);

    const canDiscard = useMemo(() => {
        if (!hasPermission(Permission.AdminPurchaseOrdersCreate)) {
            return false;
        }
        return purchaseOrder && purchaseOrder.status === PurchaseOrderStatus.Draft;
    }, [hasPermission, purchaseOrder]);

    const renderContent = () => {
        if (purchaseOrderIsLoading) {
            return <ComponentLoader />;
        }

        if (!purchaseOrder) {
            return (
                <CenteredBox>
                    <Placeholder
                        illustration={SvgIllustration.Error}
                        title='Failed to fetch PO details'
                        message={`We could not find the details for PO #${purchaseOrderId}`}
                    />
                </CenteredBox>
            );
        }

        switch (purchaseOrderTab) {
            case PurchaseOrderTab.Details:
                return <PurchaseOrderDetailsTab purchaseOrder={purchaseOrder} />;
            case PurchaseOrderTab.Items:
                return <PurchaseOrderItemsTab purchaseOrderItems={purchaseOrder.purchaseOrderItems} />;
        }
    };

    const moreMenu = (
        <MoreMenu
            items={[
                {
                    label: 'Cancel',
                    action: () => setModal(PurchaseOrderModal.Cancel),
                    visible: isPurchaseOrderCancellable,
                    icon: SvgIcon.Cross,
                },
                {
                    label: 'Close',
                    action: () => setModal(PurchaseOrderModal.Close),
                    visible: isPurchaseOrderClosable,
                    icon: SvgIcon.Cross,
                },
                {
                    label: 'Discard',
                    action: () => setModal(PurchaseOrderModal.Discard),
                    visible: canDiscard,
                    icon: SvgIcon.Trash,
                },
                auditLogsMenu,
            ]}
        />
    );

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <Page pageId='purchase-order-details'>
                <PageHeader
                    title={`Purchase order ${purchaseOrder?.purchaseOrderReference}`}
                    rightSlot={
                        <>
                            {purchaseOrderTab === PurchaseOrderTab.Details && (
                                <Button
                                    type='secondary'
                                    label='Edit'
                                    leftIcon={SvgIcon.Edit}
                                    onClick={() => handleOpenEditPurchaseOrder(purchaseOrderTab)}
                                    disabled={!isPurchaseOrderEditable}
                                />
                            )}
                            {moreMenu}
                        </>
                    }
                    tabsSlot={
                        <Tabs active={purchaseOrderTab} onChange={onChangeDetailsTab}>
                            {allPurchaseOrderTabs.map(tab => (
                                <Tab key={tab} id={tab} label={trTab(tab)} />
                            ))}
                        </Tabs>
                    }
                />
                <Box flex='auto' relative>
                    {renderContent()}
                </Box>
                <Modals>
                    {modal === PurchaseOrderModal.Cancel && !!purchaseOrder && (
                        <CancelPurchaseOrderModal
                            purchaseOrderId={purchaseOrder.id}
                            purchaseOrderReference={purchaseOrder.purchaseOrderReference}
                            onClose={() => setModal(null)}
                        />
                    )}
                    {modal === PurchaseOrderModal.Close && !!purchaseOrder && (
                        <ClosePurchaseOrderModal
                            purchaseOrderId={purchaseOrder.id}
                            purchaseOrderReference={purchaseOrder.purchaseOrderReference}
                            onClose={() => setModal(null)}
                        />
                    )}
                    {modal === PurchaseOrderModal.Discard && !!purchaseOrder && (
                        <DiscardPurchaseOrderModal
                            purchaseOrderId={purchaseOrder.id}
                            purchaseOrderReference={purchaseOrder.purchaseOrderReference}
                            onClose={() => setModal(null)}
                            onDraftDiscarded={onDeleted}
                        />
                    )}
                </Modals>
            </Page>
        </Overlay>
    );
};
